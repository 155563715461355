import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import Logo from '../../asset/cuck_logo_400x400.png';
import NavbarToggle from '../../asset/navbar_toggle.svg';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { AppContext } from "../../utils/AppContext";

import { Image } from 'react-bootstrap';
import Asset1 from '../../asset/cuck_logo_400x400.png';

import Dexcreener from '../../asset/dexscreener-icon.png';
import Dextools from '../../asset/dextool-icon.png';
import Telegram from '../../asset/telegram-icon.png';
import Twitter from '../../asset/twitter-icon.png';

const News1 = () => {

    const appContext = useContext(AppContext);

    const desktopStyles = {
        contentContainerStyle: {
            display: "flex",
            flexDirection: "column",
            height: (window.innerHeight-80)+"px",
            padding: "40px 80px",
            justifyContent: 'center',
            backgroundColor: appContext.colors.primaryYellow
        },

        headlineStyle: {
            fontSize: "72px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryOrange
        },

        subtitleStyle: {
            fontSize: "22px",
            fontWeight: "500",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        newsTitleStyle: {
            fontSize: "28px",
            fontWeight: "600",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 100px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "20%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/20)+"px",
            padding: "0px",
            marginTop: '20px',
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '30px',
            justifyContent: 'center',
            alignItems: 'center',
        } 
    }

    const mobileStyles = {
        contentContainerStyle: {
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            padding: "30px 60px",
            backgroundColor: appContext.colors.primaryYellow
        },

        headlineStyle: {
            fontSize: "48px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryOrange
        },

        subtitleStyle: {
            fontSize: "14px",
            fontWeight: "500",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        newsTitleStyle: {
            fontSize: "16px",
            fontWeight: "500",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "30%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/10)+"px",
            padding: "0px",
            marginTop: '20px',
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
        }

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return (
        <>
            <div style={styles.contentContainerStyle}>
                <h1 style={styles.headlineStyle}>$CUCK Community Take Over</h1>

                <h3 style={styles.subtitleStyle}>
                A new game is set to be released for cryptocurrency Cat Duck, promising users a new way to accumulate the growing meme coin.
                </h3>
                <h3 style={styles.subtitleStyle}>
                The game concept involves users touching their android screens when they see the iconic Cat Duck meme, before a timer elapses, to climb the leaderboard.
                </h3>
                <h3 style={styles.subtitleStyle}>
                A Cat Duck community spokesman said the Cuck Tap game was simple and fun.
                </h3>
                <h3 style={styles.subtitleStyle}>
                “This is the first of our game releases and our community is already in competition to see who can get the most taps,” the spokesman said.
                </h3>
                <h3 style={styles.subtitleStyle}>
                “Cat Duck is made up of half cat and half duck, in a marriage known as Cuck.
                </h3>
                <h3 style={styles.subtitleStyle}>
                “We have one message to players: don’t cuck this up!” he said.
                </h3>
                <h3 style={styles.subtitleStyle}>
                “All they have to do is tap our iconic logo to climb the leaderboard.” 
                </h3>
                <h3 style={styles.subtitleStyle}>
                A second game release is expected later this year. Cat Duck coin can be bought on the Solana blockchain, using Jupiter and Raydium exchanges.
                </h3>
                <h3 style={styles.subtitleStyle}>
                A Community Takeover (CTO) took place in April this year after an original creator abandoned the project.
                </h3>
                <h3 style={styles.subtitleStyle}>
                The team said the community had grown significantly since the organic takeover, with more than 700 holders and a large team of active members.
                </h3>
                <h3 style={styles.subtitleStyle}>
                For more information go to catduck.lol or <a href="https://x.com/Cucksol_CTO">Cucksol_CTO</a> on X.com
                </h3>

            </div>
        </>
    )
}

export default News1;