import { Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import MemePage from './pages/MemePage';
import NewsPage from './pages/NewsPages/NewsPage1';
import NewsPage2 from './pages/NewsPages/NewsPage2';
import NewsPage3 from './pages/NewsPages/NewsPage3';
import './App.css';

function App() {

  return (
    <Routes>
      <Route index element={<LandingPage/>}/>
      <Route path="/memes" element={<MemePage/>}/>
      <Route path="/news1" element={<NewsPage/>}/>
      <Route path="/news2" element={<NewsPage2/>}/>
      <Route path="/news3" element={<NewsPage3/>}/>
    </Routes>
  );
}

export default App;
