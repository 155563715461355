import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import Logo from '../asset/cuck_logo_400x400.png';
import NavbarToggle from '../asset/navbar_toggle.svg';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { AppContext } from "../utils/AppContext";

import { Image } from 'react-bootstrap';
import Asset1 from '../asset/cuck_logo_400x400.png';
import JupiterLogo from '../asset/jupiter-ag-jup-logo.png';

import meme1 from '../asset/contents/1.jpg';
import meme2 from '../asset/contents/2.jpg';
import meme3 from '../asset/contents/3.jpg';
import meme4 from '../asset/contents/4.jpg';

const GallerySection = () => {

    const appContext = useContext(AppContext);

    const desktopStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: (window.innerHeight-80)+"px",
            padding: "40px",
            justifyContent: 'center',
            backgroundColor: appContext.colors.primaryOrange
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },


        contractStyle: {
            textAlign: "center",
            fontSize: "25px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.secondaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 100px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack,
            backgroundColor: appContext.colors.primaryYellow,
        },

        imageAsset1: {
            width: "20%",
            padding: "10px"
        },

        imageAsset2: {
            width: "18%",
            padding: "20px"
        },

        statsContainerStyle: {
            textAlign: "center",
            padding: "40px 20px",
            width: "70%",
            marginLeft: (window.innerWidth * 15 / 100) + "px",
            marginRIght: (window.innerWidth * 15 / 100) + "px"
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }

    const mobileStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            height: (window.innerHeight-60)+"px",
            padding: "30px",
            backgroundColor: appContext.colors.primaryOrange
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "140%",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        contractStyle: {
            textAlign: "center",
            fontSize: "8px",
            fontWeight: "400",
            marginTop: '20px',
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.secondaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack,
            backgroundColor: appContext.colors.primaryYellow,
        },

        imageAsset1: {
            width: "30%",
            padding: "10px",
        },

        imageAsset2: {
            width: "27%",
            padding: "10px"
        },

        statsContainerStyle: {
            textAlign: "center",
            padding: "0px",
            margin: "auto",
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
        }

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return appContext.mobileMode ?(
        <>
            <div style={styles.contentContainerStyle}>

                <h1 style={styles.headlineStyle}>Content & Memes</h1>
                <h3 style={styles.subtitleStyle}>
                Everyone can get our contents/memes and spread the word
                </h3>
                <div style={styles.logoContainerStyle}>
                    <Image style={styles.imageAsset1} src={meme1} />
                    <Image style={styles.imageAsset2} src={meme2} />
                </div>
                <div style={styles.logoContainerStyle}>
                    <Image style={styles.imageAsset1} src={meme3} />
                    <Image style={styles.imageAsset2} src={meme4} />
                </div>
                <Link style={styles.downloadButtonStyle} to="memes" href="memes" name="memes">
                    Open Gallery
                </Link>
                

            </div>
        </>
    ):(
        <>
            <div style={styles.contentContainerStyle}>

                <h1 style={styles.headlineStyle}>Content & Memes</h1>
                <h3 style={styles.subtitleStyle}>
                Everyone can get our contents/memes and spread the word
                </h3>
                <div style={styles.logoContainerStyle}>
                    <Image style={styles.imageAsset1} src={meme1} />
                    <Image style={styles.imageAsset2} src={meme2} />
                    <Image style={styles.imageAsset1} src={meme3} />
                    <Image style={styles.imageAsset2} src={meme4} />
                </div>
                <Link style={styles.downloadButtonStyle} to="memes" href="memes" name="memes">
                    Open Gallery
                </Link>
                

            </div>
        </>
    )
}

export default GallerySection;