import React, { createContext, useState, useEffect } from "react";
import useCheckMobileScreen from "./ResponsiveStyleCheck";

const AppContext = createContext();

const AppContextProvider = (props) => {
    const twindow = typeof window !== 'undefined' ? window : {};

    const mobileMode = useCheckMobileScreen();
    const [ isDarkMode, setIsDarkMode ] = useState(false);
    const multiModeStyle = {
        mainTextColor: isDarkMode ? "white" : "black",
        secondaryTextColor: isDarkMode ? "#B0B0B0" : "#7B7B7B",
        cardBackgroundColor: isDarkMode ? "#3E3E3E" : "white"
    }
    const fontFamily = {
      poppins: 'Poppins, sans-serif',
      inter: 'inter, sans-serif',
      wedges: 'Wedges, sans-serif'
    }

    const colors = {
        primaryBlue: "#2E80EB",
        primaryWhite: "#FFFFFF",
        primaryBlack: "#010101",
        secondaryBlack: "#3E3E3E",
        primaryGrey: "#959595",
        secondaryGrey: "#7B7B7B",

        primaryYellow: "#ffde15",
        primaryOrange: "#ea8722"
    }

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    }

    const tosStyles = {
        tosContentTitleStyle: {
          fontWeight: "700",
          marginTop: "50px",
          marginBottom: "50px",
          color: colors.primaryBlack
        },
    
        tosContentSubtitleStyle: {
          fontWeight: "500",
          marginTop: "20px",
          marginBottom: "20px",
          color: colors.primaryBlack
        },
    
        tosContentDescriptionStyle: {
          fontWeight: "400",
          lineHeight: "150%",
          color: colors.primaryBlack
        },
    
        tosContentLastChangedStyle: {
          fontWeight: "700",
          color: colors.primaryBlack
        },
    
        tosContentBoldStyle: {
          color: colors.primaryBlack
        },
    
        tosListBulletStyle: {
          color: colors.primaryBlack
        }
      }

    return (
        <AppContext.Provider
        value={{
            mobileMode,
            isDarkMode,
            setIsDarkMode,
            toggleDarkMode,
            multiModeStyle,
            colors,
            tosStyles,
            fontFamily
        }}
        >
        {props.children}
        </AppContext.Provider>
    );
}
export { AppContext, AppContextProvider };