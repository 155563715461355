import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import Logo from '../../asset/cuck_logo_400x400.png';
import NavbarToggle from '../../asset/navbar_toggle.svg';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { AppContext } from "../../utils/AppContext";

import { Image } from 'react-bootstrap';
import Asset1 from '../../asset/cuck_logo_400x400.png';

import Dexcreener from '../../asset/dexscreener-icon.png';
import Dextools from '../../asset/dextool-icon.png';
import Telegram from '../../asset/telegram-icon.png';
import Twitter from '../../asset/twitter-icon.png';

const News3 = () => {

    const appContext = useContext(AppContext);

    const desktopStyles = {
        contentContainerStyle: {
            display: "flex",
            flexDirection: "column",
            height: (window.innerHeight-80)+"px",
            padding: "40px 80px",
            justifyContent: 'center',
            backgroundColor: appContext.colors.primaryYellow
        },

        headlineStyle: {
            fontSize: "72px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryOrange
        },

        subtitleStyle: {
            fontSize: "22px",
            fontWeight: "500",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        newsTitleStyle: {
            fontSize: "28px",
            fontWeight: "600",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 100px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "20%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/20)+"px",
            padding: "0px",
            marginTop: '20px',
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '30px',
            justifyContent: 'center',
            alignItems: 'center',
        } 
    }

    const mobileStyles = {
        contentContainerStyle: {
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            padding: "30px 60px",
            backgroundColor: appContext.colors.primaryYellow
        },

        headlineStyle: {
            fontSize: "48px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryOrange
        },

        subtitleStyle: {
            fontSize: "14px",
            fontWeight: "500",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        newsTitleStyle: {
            fontSize: "16px",
            fontWeight: "500",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "30%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/10)+"px",
            padding: "0px",
            marginTop: '20px',
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
        }

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return (
        <>
            <div style={styles.contentContainerStyle}>
                <h1 style={styles.headlineStyle}>Cat Duck Token Listed on Azbit Cryptocurrency Exchange</h1>

                <h3 style={styles.subtitleStyle}>
                Cat Duck is excited to announce its listing on cryptocurrency exchange Azbit, as popularity for the iconic meme coin increases. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                The Cat Duck (CUCK) token created on the Solana blockchain was officially listed on Azbit on August 19.
                </h3>
                <h3 style={styles.subtitleStyle}>
                The CUCK token is available for pairs trading with USDT and follows the launch of play-to-earn game Cuck Tap earlier this month. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                A Cat Duck spokesman said the community was excited to have the meme coin increase its presence online with the Azbit listing. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                “We are thrilled about sharing Cat Duck with the world,” the spokesman said.
                </h3>
                <h3 style={styles.subtitleStyle}>
                “This is our time to shine and for people to join our growing movement. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                “We have a strong community who are building every day. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                “Games, memes, merchandise are just the start of our plans. We plan to takeover, one Cuck at a time.” 
                </h3>
                <h3 style={styles.subtitleStyle}>
                Play-to-earn game Cuck Tap was released on Google Store in August. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                A second game release is expected later this year. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                Cat Duck coin can also be traded on the Solana blockchain, using Jupiter and Raydium exchanges. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                A Community Takeover (CTO) took place in April this year after an original creator abandoned the project. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                Go to <a href="https://azbit.com">https://azbit.com</a> to buy Cat Duck. 
                </h3>
                <h3 style={styles.subtitleStyle}>
                Download Cuck Tap on the <a href="https://play.google.com/store/apps/details?id=com.cuck.cucktap">Google Playstore</a>
                </h3>
                <h3 style={styles.subtitleStyle}>
                For more information go to <a href="https://x.com/cucksol_cto">https://x.com/cucksol_cto</a> on X.com or join the Telegram group at <a href="https://t.me/cuckctoSOL">https://t.me/cuckctoSOL</a>
                </h3>

            </div>
        </>
    )
}

export default News3;