import React from "react";
import MainSection from "../sections/MainSection";
import BuySection from "../sections/BuySection";
import NewsSection from "../sections/NewsSection";
import GalleryFullSection from "../sections/GalleryFullSection";
import FooterSection from "../sections/FooterSection";
import { Outlet } from "react-router-dom";
import {Helmet} from "react-helmet";

const MemePage = () => {

    return(
        <>  
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Cuck (Cat Duck)</title>

                    <meta property="og:title" content="Cat Duck ($CUCK)" />
					<meta property="og:description" content="Hi I'm a cat duck so I'm a $CUCK" />
					<meta property="og:type" content="website"/>
					
					<meta name="twitter:card" content="summary_large_image" />
					<meta property="twitter:domain" content="catduck.lol" />
					<meta name="twitter:title" content="Cat Duck ($CUCK)" />
					<meta name="twitter:description" content="Hi I'm a cat duck so I'm a $CUCK" />
                </Helmet>
                <GalleryFullSection/>
                <FooterSection/>
            </div>
        </>
    )
}

export default MemePage;