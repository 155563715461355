import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import Logo from '../asset/cuck_logo_400x400.png';
import NavbarToggle from '../asset/navbar_toggle.svg';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { AppContext } from "../utils/AppContext";

import { Image } from 'react-bootstrap';
import Asset1 from '../asset/cuck_logo_400x400.png';

import Dexcreener from '../asset/dexscreener-icon.png';
import Dextools from '../asset/dextool-icon.png';
import Telegram from '../asset/telegram-icon.png';
import Twitter from '../asset/twitter-icon.png';

const NewsSection = () => {

    const appContext = useContext(AppContext);

    const desktopStyles = {
        contentContainerStyle: {
            display: "flex",
            flexDirection: "column",
            height: (window.innerHeight-80)+"px",
            padding: "40px 80px",
            justifyContent: 'center',
            backgroundColor: appContext.colors.primaryYellow
        },

        headlineStyle: {
            fontSize: "72px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryOrange
        },

        subtitleStyle: {
            fontSize: "22px",
            fontWeight: "500",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        newsTitleStyle: {
            fontSize: "28px",
            fontWeight: "600",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 100px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "20%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/20)+"px",
            padding: "0px",
            marginTop: '20px',
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '30px',
            justifyContent: 'center',
            alignItems: 'center',
        } 
    }

    const mobileStyles = {
        contentContainerStyle: {
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            padding: "30px 60px",
            backgroundColor: appContext.colors.primaryYellow
        },

        headlineStyle: {
            fontSize: "48px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryOrange
        },

        subtitleStyle: {
            fontSize: "14px",
            fontWeight: "500",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        newsTitleStyle: {
            fontSize: "18px",
            fontWeight: "600",
            margin: "5px 0px",
            fontFamily: appContext.fontFamily.inter,
            color: appContext.colors.primaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "30%",
            padding: "0px"
        },

        socialsIcon: {
            width: (window.innerWidth/10)+"px",
            padding: "0px",
            marginTop: '20px',
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
        }

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return (
        <>
            <div style={styles.contentContainerStyle}>
                <h1 style={styles.headlineStyle}>Press Releases</h1>

                <Link to="news3" href="news3" name="news3" style={{textDecoration: 'none', color: 'inherit', margin: '20px 0px'}}>
                    <h2 style={styles.newsTitleStyle}>
                    Cat Duck Token Listed on Azbit Cryptocurrency Exchange
                    </h2>
                    <h3 style={styles.subtitleStyle}>
                    Cat Duck is excited to announce its listing on cryptocurrency exchange Azbit... <b>Read more</b>
                    </h3>
                    <h3 style={styles.subtitleStyle}>
                        <b>18 August</b>
                    </h3>
                </Link>

                <Link to="news2" href="news2" name="news2" style={{textDecoration: 'none', color: 'inherit', margin: '20px 0px'}}>
                    <h2 style={styles.newsTitleStyle}>
                    New Cat Duck Game Cuck Tap released on Google Playstore
                    </h2>
                    <h3 style={styles.subtitleStyle}>
                    An exciting new game has been released for cryptocurrency Cat Duck, promising users a new way...  <b>Read more</b>
                    </h3>
                    <h3 style={styles.subtitleStyle}>
                        <b>1 August</b>
                    </h3>
                </Link>

                

            </div>
        </>
    )
}

export default NewsSection;