import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Button, Row, Col, Modal } from 'react-bootstrap';
import Logo from '../asset/cuck_logo_400x400.png';
import NavbarToggle from '../asset/navbar_toggle.svg';
import { Link } from "react-router-dom";
import $ from 'jquery';
import { AppContext } from "../utils/AppContext";

import { Image } from 'react-bootstrap';
import Asset1 from '../asset/cuck_logo_400x400.png';
import JupiterLogo from '../asset/jupiter-ag-jup-logo.png';

const BuySection = () => {

    const appContext = useContext(AppContext);

    const desktopStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: (window.innerHeight-80)+"px",
            padding: "40px",
            justifyContent: 'center',
            backgroundColor: appContext.colors.primaryWhite
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "22px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.secondaryBlack
        },


        contractStyle: {
            textAlign: "center",
            fontSize: "25px",
            fontWeight: "400",
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.secondaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 100px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "20%",
            padding: "0px"
        },

        imageAsset2: {
            width: "18%",
            padding: "0px"
        },

        statsContainerStyle: {
            textAlign: "center",
            padding: "40px 20px",
            width: "70%",
            marginLeft: (window.innerWidth * 15 / 100) + "px",
            marginRIght: (window.innerWidth * 15 / 100) + "px"
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }

    const mobileStyles = {
        contentContainerStyle: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: 'center',
            height: (window.innerHeight-60)+"px",
            padding: "30px",
            backgroundColor: appContext.colors.primaryWhite
        },

        headlineStyle: {
            textAlign: "center",
            fontSize: "30px",
            fontWeight: "500",
            padding: "0px",
            margin: "0px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryBlack
        },

        subtitleStyle: {
            textAlign: "center",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "140%",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.secondaryBlack
        },

        contractStyle: {
            textAlign: "center",
            fontSize: "8px",
            fontWeight: "400",
            marginTop: '20px',
            fontFamily: appContext.fontFamily.poppins,
            color: appContext.colors.secondaryBlack
        },

        downloadButtonStyle: {
            textDecoration: "none",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "300",
            padding: "18px 32px",
            lineHeight: "normal",
            borderRadius: "50px",
            fontFamily: appContext.fontFamily.wedges,
            color: appContext.colors.primaryWhite,
            backgroundColor: appContext.colors.primaryOrange,
        },

        imageAsset1: {
            width: "30%",
            padding: "0px"
        },

        imageAsset2: {
            width: "27%",
            padding: "0px"
        },

        statsContainerStyle: {
            textAlign: "center",
            padding: "0px",
            margin: "auto",
        },

        logoContainerStyle: {
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
        }

    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    return (
        <>
            <div style={styles.contentContainerStyle}>

                <div style={styles.logoContainerStyle}>
                    <Image style={styles.imageAsset1} src={Asset1} />
                    <Image style={styles.imageAsset2} src={JupiterLogo} />
                </div>

                <h1 style={styles.headlineStyle}>How to buy $CUCK</h1>
                <h3 style={styles.subtitleStyle}>
                You can buy $CUCK on Jupiter by clicking the button below
                </h3>
                <Link style={styles.downloadButtonStyle} onClick={() => {window.open("https://jup.ag/swap/SOL-7fUHwmUCGmrFwJLuoVxe699gfABD8Uy9CZAbamcvG4q6", '_blank').focus();}}>
                    Buy on Jupiter
                </Link>
                <h3 style={styles.contractStyle}>
                CA: 7fUHwmUCGmrFwJLuoVxe699gfABD8Uy9CZAbamcvG4q6
                </h3>
                

            </div>
        </>
    )
}

export default BuySection;